var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checklist scroll-bar",class:{ 'checklist--collapsed': !!_vm.selected }},[_c('div',{staticClass:"checklist-list"},[(!_vm.selected)?_c('closeIcon',{staticClass:"close_icon absolute top-3.5 right-3.5 mr-5 w-4 h-4 cursor-pointer text-base-content fill-current stroke-current",on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('div',{staticClass:"checklist-list__header"},[_c('div',{staticClass:"header__left"},[_c('search-field',{attrs:{"placeholder":"Search By Activity"}}),_c('div',{staticClass:"filter-controls"},[_c('dropdown',{staticClass:"w-48 2xl:w-56",attrs:{"width":"min-content","placeholder":"Filter Activity","multiple":"","colors":{
              bg: '#fff',
              text: '#282828',
              border: '#0D69D5',
              svg: '#0D69D5',
            },"config":{ label: 'title', trackBy: 'value' },"options":_vm.options.activity},on:{"select":_vm.onSelectActivity,"option-selected":_vm.handleOptionSelected,"option-deselected":_vm.handleOptionDeselected},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('filter-icon')]},proxy:true}])})],1)],1),_c('div',{staticClass:"header__right"},[_c('dropdown',{attrs:{"menu":"","placeholder":"Collapse All","colors":{
              bg: '#0D69D5',
            }},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('collapse-all-icon')]},proxy:true}])}),_c('dropdown',{attrs:{"min-width":"80px","placeholder":"Sort","colors":{
              bg: '#0D69D5',
            }},scopedSlots:_vm._u([{key:"caret",fn:function(){return [_c('sort-icon')]},proxy:true}])})],1)]),_c('div',{staticClass:"checklist-list__content"},_vm._l((_vm.filterActivityData),function(item){return _c('checklist-item',{key:item.id,ref:item.id,refInFor:true,attrs:{"item":item,"selected":!!(_vm.selected && item.id === _vm.selected.id),"compact":!!_vm.selected},on:{"select":_vm.selectCard,"showEmailAttachments":function($event){return _vm.showEmailAttachments($event)}}})}),1)],1),(_vm.selected && _vm.details[_vm.selected.type])?_c('div',{staticClass:"checklist-details"},[_c(_vm.details[_vm.selected.type],{key:_vm.selected.id,tag:"component",attrs:{"data":_vm.selected},on:{"close":_vm.deselect}})],1):_vm._e(),(!_vm.selected || !_vm.details[_vm.selected.activity_type])?_c('AttachmentsModal',{ref:"email-attachments",attrs:{"isLoading":_vm.loadingStates.attachments,"modalTitle":"Attachments","showSearch":false,"lists":_vm.attachments},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"text-primary",on:{"click":function($event){return _vm.handleClickAttachments(item)}}},[_c('span',{staticClass:"text-gray-700 font-semibold"},[_vm._v(_vm._s(item.index)+". ")]),_c('span',{staticClass:"text-blue-700"},[_vm._v(" "+_vm._s(item.name))])])]}}],null,false,152010096)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }