<template>
<component
  :is="cards[item.type]"
  :item="item"
  @click.native="$emit('select', item)"
  :selected="selected"
  :compact="compact"
  @showEmailAttachments="$emit('showEmailAttachments', $event)" 
/>
</template>

<script>
const cardEmail = () => import("./cards/card-email.vue");
const cardCall = () => import("./cards/card-call.vue");
const cardStatusChange = () => import("./cards/card-status-change.vue");
const cardNote = () => import("./cards/card-note.vue");
const cardTask = () => import("./cards/card-task.vue");
const cardTasks = () => import("./cards/card-tasks.vue");

export default {
  name: 'checklist-item',
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    cards() {
      return {
        'email': cardEmail,
        'call': cardCall,
        'status_change': cardStatusChange,
        'note': cardNote,
        'task': cardTask,
        'tasks': cardTasks,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>