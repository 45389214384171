<template>
<div
  class="checklist scroll-bar" :class="{ 'checklist--collapsed': !!selected }"
>
  <div class="checklist-list">
    <closeIcon v-if="!selected" class="close_icon absolute top-3.5 right-3.5 mr-5 w-4 h-4 cursor-pointer text-base-content fill-current stroke-current" @click="$emit('close')" />

    <div class="checklist-list__header">
      <div class="header__left">
        <search-field placeholder="Search By Activity" />
        <div class="filter-controls">
          <dropdown
            class="w-48 2xl:w-56"
            width="min-content"
            placeholder="Filter Activity"
            multiple
            :colors="{
              bg: '#fff',
              text: '#282828',
              border: '#0D69D5',
              svg: '#0D69D5',
            }"
            :config="{ label: 'title', trackBy: 'value' }"
            :options="options.activity"
            @select="onSelectActivity"
            @option-selected="handleOptionSelected"
            @option-deselected="handleOptionDeselected"
          >
            <template #before>
              <filter-icon/>
            </template>
          </dropdown>
          <!-- <dropdown
            width="160px"
            placeholder="Checks"
            :colors="{
              bg: '#fff',
              text: '#282828',
              border: '#0D69D5',
              svg: '#0D69D5',
            }"
            :config="{ label: 'title', trackBy: 'value' }"
            :options="options.check"
          >
            <template #after>
              <sort-icon/>
            </template>
          </dropdown> -->
        </div>
      </div>
      <div class="header__right">
          <dropdown
            menu
            placeholder="Collapse All"
            :colors="{
              bg: '#0D69D5',
            }"
          >
            <template #before>
              <collapse-all-icon/>
            </template>
          </dropdown>
          <dropdown
            min-width="80px"
            placeholder="Sort"
            :colors="{
              bg: '#0D69D5',
            }"
          >
            <template #caret>
              <sort-icon/>
            </template>
          </dropdown>
      </div>
    </div>
    <div class="checklist-list__content">
      <checklist-item
        v-for="item in filterActivityData"
        :ref="item.id"
        :key="item.id"
        :item="item"
        @select="selectCard"
        :selected="!!(selected && item.id === selected.id)"
        @showEmailAttachments="showEmailAttachments($event)"
        :compact="!!selected"
      />
    </div>
  </div>
  <div
    v-if="selected && details[selected.type]"
    class="checklist-details"
  >
    <component
      :is="details[selected.type]"
      :key="selected.id"
      :data="selected"
      @close="deselect"
    />
  </div>
  <AttachmentsModal v-if="!selected || !details[selected.activity_type]" :isLoading="loadingStates.attachments" modalTitle="Attachments" :showSearch="false"  :lists="attachments" ref="email-attachments">
    <template #item="{ item }">
        <div class="text-primary" @click="handleClickAttachments(item)">
            <span class="text-gray-700 font-semibold">{{item.index}}.  </span>
            <span class="text-blue-700"> {{item.name}}</span>
         </div>
    </template>
    </AttachmentsModal>
</div>
</template>

<script>
import axios from "@/axios";
import checklistItem from './components/checklist-item.vue';
import closeIcon from "@/assets/icons/profile-view/close-icon.svg";
import dropdown from '@/components/dropdown-base';
import searchField from '@/components/search-field';
import { EventBus } from "@/main.js";
// import sortIcon from '@/assets/icons/dashboard/sort.svg';
import sortIcon from '@/assets/icons/dashboard/dropdown-double.svg';
import filterIcon from '@/assets/icons/dashboard/filter.svg';
import collapseAllIcon from '@/assets/icons/dashboard/collapse-all.svg';

const emailThread = () => import("@/components/dashboard-components/checklist/components/email-thread");

/* Activity Icons */
const emailIcon = () => import("@/assets/icons/dashboard/activity/email.svg");
const callIcon = () => import("@/assets/icons/dashboard/activity/call.svg");
const noteIcon = () => import("@/assets/icons/dashboard/activity/note.svg");
// const replaceIcon = () => import("@/assets/icons/dashboard/activity/replace.svg");
const taskIcon = () => import("@/assets/icons/dashboard/activity/task.svg");

/* Label Icons */
const briefcaseIcon = () => import("@/assets/icons/dashboard/label/briefcase.svg");
const graduateIcon = () => import("@/assets/icons/dashboard/label/graduate.svg");
const AttachmentsModal = () => import("@shared/components/listModal");

export default {
  name: 'checklist',
  props: {
    list: {
      type: Array,
      required: true,
    }
  },
  components: {
    checklistItem,
    dropdown,
    searchField,
    sortIcon,
    filterIcon,
    collapseAllIcon,
    AttachmentsModal,
    closeIcon
  },
  data() {
    return {
      selected: null,
      options: {
        // TODO; get options from list
        activity: [
          { title: 'Email', value: 'Email', icon: emailIcon },
          { title: 'Call', value: 'Call', icon: callIcon },
          { title: 'Note', value: 'Note', icon: noteIcon },
          { title: 'Task', value: 'Task', icon: taskIcon },
        ],
        check: [
          { title: 'UCL', value: 'ucl', icon: graduateIcon },
          { title: 'Microsoft', value: 'microsoft', icon: briefcaseIcon },
          { title: 'Facebook', value: 'facebook', icon: briefcaseIcon },
          { title: 'Google', value: 'google', icon: briefcaseIcon },
        ],
      },
      details: {
        'email': emailThread,
      },
      activityData: this.list,
      filterActivityData:[],
      selectedActivityOptions: [],
      attachments: [],
      loadingStates:{
         attachments: false
          }
    }
  },
  async created() {
        EventBus.$on("activityAction", (e) => {
            this.$emit("activityAction", e);
            if (this.actionHandlers[e.type]) this.actionHandlers[e.type](e);
        });
    },
  computed: {
    actionHandlers() {
            return {
                check: (e) => this.checkEmail(e.data),
            };
        },
  },
  async mounted() {
    this.activityData = this.list;
    this.filterActivityData = this.list;
  },
  beforeDestroy() {
        EventBus.$off("activityAction");
    },
  watch: {
    list: function (val) {
      this.activityData = val;
      this.onSelectActivity()
    },
  },
  methods: {
    handleOptionSelected(selectedOptions) {
      this.onSelectActivity(selectedOptions);
    },
    handleOptionDeselected(deselectedOptions) {
      const updatedSelectedOptions = this.selectedActivityOptions.filter(
        (selectedOption) =>
          !deselectedOptions.some(
            (deselectedOption) =>
              selectedOption.value.toLowerCase() ===
              deselectedOption.value.toLowerCase()
          )
      );
      this.onSelectActivity(updatedSelectedOptions);
    },
    onSelectActivity(selectedOptions=[]) {
      if (selectedOptions.length > 0) {
        this.selectedActivityOptions = selectedOptions;
        this.filterActivityData = this.activityData.filter((item) =>
          this.selectedActivityOptions.some(
            (selectedOption) =>
              item.activity_type.toLowerCase() ===
              selectedOption.value.toLowerCase()
          )
        );
      } else {
        this.filterActivityData = this.activityData;
        this.selectedActivityOptions = [];
      }
    },
    selectCard(card) {
      this.selected = card;
    },
    deselect() {
      this.selectCard(null);
    },
    showEmailAttachments({data, type}) {
      this.attachments = data?.attachments?.map((el, index) => ({id: el.key,index:index + 1, name: el.key.substring(el.key.lastIndexOf('/') + 1) })) || []
      this.$refs[type].showListModal();        
    },
    async handleClickAttachments(item) {
      this.loadingStates.attachments = true
      try {
        let { data } = await axios.get(`/email/attachment/url?key=${item.id}`)
        window.open(data.url, "_blank");
      } catch(error) {
        console.log(error)
       }
       this.loadingStates.attachments = false
      },
      async checkEmail(payload) {
            const activityType = {
                email: {
                    acknowledge: { url: `email/ack/${payload.id}`, method: "PUT" },
                    unacknowledge: { url: `email/ack/${payload.id}?ack=false`, method: "PUT" },
                    key: "is_ack",
                },
                email_sequence: {
                    acknowledge: { url: `email/ack/${payload.id}`, method: "PUT" },
                    unacknowledge: { url: `email/ack/${payload.id}?ack=false`, method: "PUT" },
                    key: "is_ack",
                },
                tasks: {
                    acknowledge: { url: `case-tasks/acknowledge/${payload.id}`, method: "POST" },
                    unacknowledge: { url: `case-tasks/unacknowledge/${payload.id}`, method: "POST" },
                    key: "acknowledged",
                },
                note: {
                    acknowledge: { url: `case-notes/acknowledge/${payload.id}`, method: "POST" },
                    unacknowledge: { url: `case-notes/unacknowledge/${payload.id}`, method: "POST" },
                    key: "acknowledged",
                },
            };
            const selCat = activityType[payload.activity_type];
            payload[selCat["key"]] = !payload[selCat["key"]];
            try {
                if (payload[selCat["key"]]) {
                    await axios({
                        method: selCat["acknowledge"]["method"],
                        url: selCat["acknowledge"]["url"],
                    });
                } else {
                    await axios({
                        method: selCat["unacknowledge"]["method"],
                        url: selCat["unacknowledge"]["url"],
                    });
                }
            } catch (error) {
                console.log(error, "[+] error while acknowledging activity");
            }
        },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.checklist {
  display: flex;
  gap: 40px;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0 toRem(20px);
  transition: padding .1s linear;
  .checklist-list {
    flex-shrink: 0;
    padding: toRem(40px);
    background-color: #fff;
    border-radius: toRem(24px);
    display: flex;
    flex-direction: column;
    gap: toRem(40px);
    width: 100%;
    max-width: 100%;
    transition: max-width .1s ease-out;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      .header {
        &__left, &__right {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        &__left {
          .filter-controls {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
          }
        }
        &__right {
          align-items: flex-end;
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }
  }
  &--collapsed {
    padding: 0;
    .checklist-list {
      max-width: toRem(320px);
      padding: toRem(40px) toRem(30px);
      &__header {
        .header {
          &__left {
            width: 100%;
            gap: 16px;
            .filter-controls {
              gap: 16px;
            }
          }
          &__right {
            display: none;
          }
        }
      }
    }
    .checklist-details {
      align-self: stretch;
      flex-grow: 1;
    }
  }
}
</style>